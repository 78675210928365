@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .education-themed-btn {
        @apply inline-block my-8 py-2.5 px-10 bg-education text-white rounded-md
    }
}

@layer utilities {
    .grid-dense {
        grid-auto-flow: dense;
    }
}

body {
    @apply font-roboto;
}

.app {
    @apply max-w-1920
}

.voice-menu:after {
    content: "";
    bottom: -8px;
    @apply absolute bg-bColor w-full h-1 transform -translate-x-1/2 left-1/2 rounded-2xl
}

.generic-org-active-nav:before {
    content: "";
    position: absolute;
    bottom: -10px;
    background-color: #92278f;
    width: 5px;
    height: 5px;
    transform: translateX(-50%);
    left: 50%;
    border-radius: 3rem;
}

.react-autosuggest__container {
    position: relative;
    flex-grow: 1;
}

.react-autosuggest__input {
    width: 100%;
    padding: 1rem 2rem;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 100%;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

/*omv bubble/popup*/
.bubble {
    position: relative;
    /*background: #ffffff;*/
    /*color: #474747;*/
    /*font-size: 20px;*/
    /*line-height: 120px;*/
    /*text-align: center;*/
    /*width: 250px;*/
    /*height: 120px;*/
}

.bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #ffffff transparent;
    border-width: 20px 20px 0;
    bottom: -20px;
    left: 50%;
    margin-left: -20px;
}

.custom-dot-list-style {
    justify-content: start !important;
}